
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';

import {
  EmptyMyOffers,
  EmptyOffers,
  MyOfferHeader,
  SearchLocations,
  OffersTab,
  CardListHeader,
  OfferCard,
  MainContainer,
  Header,
  FoodBankOfferCard,
  FoodBankTabs,
  ClickPercentageOfferCard
} from '@/components/offers';
import OfferCardSkeleton from '@/components/skeleton/OfferCardSkeleton.vue';
import BorderedButton from '@/components/buttons/BorderedButton.vue';
import { boostCard } from '@/components/offers/boosts';

// Services
import { OfferService } from '@/services';
import { OfferModule, UserModule } from '@/store/modules';
import { IOfferFilter, OfferDetail, IBoosters } from '@/types/offer.types';
import { IOfferTransaction, IPagedOffersTransactions, IRedemption } from '@/types/redeemed-offers.types';
import { IBasePaged } from '@/types/base.types';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';

@Component({
  components: {
    Header,
    MainContainer,
    OfferCard,
    CardListHeader,
    OffersTab,
    SearchLocations,
    MyOfferHeader,
    EmptyOffers,
    EmptyMyOffers,
    OfferCardSkeleton,
    BorderedButton,
    FoodBankOfferCard,
    boostCard,
    FoodBankTabs,
    ClickPercentageOfferCard
  }
})
export default class Offers extends Vue {
  ActiveTenant = ActiveTenant;
  currenttab = 'offers';
  allOffers: OfferDetail[] = [];
  allBoosters: IBoosters[] = [];
  tempOffers!: IBasePaged<OfferDetail>;
  redeemedOffersTransactions: IPagedOffersTransactions = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };

  isFetchingOffers = false;
  currentPage = 1;
  isLoadMoreDisabled = false;
  isFetchingMoreOffers = false;
  oliveMemberId = UserModule.user?.oliveMemberId;

  mounted() {
    this.$root.$on('changeRouteMain', (menuText: string) => {
      this.switchTab('offers');
    });
  }

  async created() {
    if (UserModule.authenticated && UserModule.hasPaymentMethod) {
      await this.getRedeemedOffersTransactions();
    }

    await this.getOliveOffers({
      pageNumber: this.currentPage,
      pageSize: 20,
      oliveMemberId: UserModule.user?.oliveMemberId
    });

    await this.getBoostersData();

    if (this.$route.query.myoffers) {
      this.currenttab = 'myoffers';
    }

    if (this.$route.query.goto) {
      const el = document.getElementById(`brand-name-${this.$route.query.goto.toString()}`) as HTMLElement;
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  switchTab(tab: string): void {
    OfferModule.setActiveTab(this.currenttab);
    this.currenttab = tab;

    if (this.currenttab === 'myoffers') {
      this.getRedeemedOffersTransactions();
    } else {
      this.allOffers = OfferModule.getOffers;
    }
  }

  scrollToBoosterCards(isMobileView: boolean): void {
    if (isMobileView) {
      const id = 'boost-holder';
      const yOffset = -88 - 10;
      const element = document.getElementById(id);
      const y = (element?.getBoundingClientRect().top ?? 0) + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      // document.getElementById('boost-holder')?.scrollIntoView(false);
    }
  }

  async getOliveOffers(filter?: IOfferFilter, isAppend?: boolean): Promise<void> {
    try {
      // Fetch data from API
      if (this.currentPage === 1) {
        this.isFetchingOffers = true;
      }

      // Check if offers are available in the store or not
      if (OfferModule.getOffers.length > 0 && !this.isFetchingMoreOffers) {
        this.tempOffers = OfferModule.getSavedResponseOffers;
      } else {
        this.tempOffers = await OfferService.fetchOffers(filter);
        OfferModule.setSavedOffers(this.tempOffers);
      }

      // Show load more button when only pages are available
      if (this.currentPage < this.tempOffers.totalNumberOfPages) {
        this.isLoadMoreDisabled = true;
      } else {
        this.isLoadMoreDisabled = false;
      }

      // Append offers into the store
      if (OfferModule.getOffers.length > 0 && this.currentPage > 1) {
        this.isFetchingMoreOffers = false;
        if (isAppend && this.tempOffers.items.length) {
          OfferModule.appendOffers(this.tempOffers.items);
          this.allOffers = OfferModule.getOffers;
        }
      } else {
        OfferModule.setOffers(this.tempOffers.items);
        this.allOffers = OfferModule.getOffers;
        this.isFetchingOffers = false;
      }
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
    }
  }
  async getRedeemedOffersTransactions(): Promise<void> {
    this.isFetchingOffers = true;
    let _redeemedOffersTransactions = await OfferService.fetchRedeemedOffersTransactions();
    // NOTE: For this information we're relying on endpoint that returns transaction data.
    // We do not transaction data specifically, just offer and redemption data.
    // Should refactor it later on and filter out expired offers on backend.
    _redeemedOffersTransactions.items = _redeemedOffersTransactions.items.filter(
      (offer) => offer.offerState !== 'expired'
    );
    this.redeemedOffersTransactions = _redeemedOffersTransactions;
    this.isFetchingOffers = false;
  }

  async getBoostersData(): Promise<void> {
    this.allBoosters = await OfferService.fetchBoosters();
    OfferModule.setBoosters(this.allBoosters);
  }

  get redemptionDates(): string[] {
    const dates = this.redeemedOffersTransactions?.items.map((x) =>
      dayjs(x.redemption.RedemptionDate).format('ddd, DD MMMM YYYY')
    );

    //Removing duplicates
    let uniqueDates = [...new Set(dates)];

    return uniqueDates;
  }

  findRedemptionsByDate(date: string): IOfferTransaction[] {
    return this.redeemedOffersTransactions?.items?.filter(
      (x) => dayjs(x.redemption.RedemptionDate).format('ddd, DD MMMM YYYY') === date
    );
  }

  checkOfferRedemption(offerId: string): boolean {
    let isRedeemed = false;
    this.redeemedOffersTransactions?.items?.map((x: any) => {
      if (x.id === offerId) {
        if (x.redeemLimitPerUser === x.redeemedCount) {
          isRedeemed = true;
        }
      }
    });
    return isRedeemed;
  }

  getRedemptionData(offerId: string): IRedemption | undefined {
    const redeemed = this.redeemedOffersTransactions?.items?.filter((x) => x.id === offerId);

    return redeemed?.length ? redeemed[0].redemption : undefined;
  }

  loadMoreOffers() {
    this.isFetchingMoreOffers = true;
    this.getOliveOffers(
      {
        pageNumber: ++this.currentPage,
        pageSize: 20,
        oliveMemberId: UserModule.user?.oliveMemberId
      },
      true
    );
  }
}
